import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { UserContext } from "@context/user";

import { Login } from "@components/login";
import { Tooltip } from "@components/tooltip";

import { Bookmark, BookmarkFill } from "@icons";

import { tagular } from "@cohesion/tagular";

export function withSave(Component, className) {
  // note: you can pass a className as the second arquement to add
  // a class to the hoc wrapper div
  function WrappedComponent({
    esid,
    type,
    slug,
    hocTagularCtx = null,
    ...rest
  }) {
    const [favorite, setFavorite] = useState(null);

    const { isLoading, user, mutate } = useContext(UserContext);

    /**
     * Create a new, generic favorite item
     *
     * FYI: If we shift to using gql mutations here, we can leverage
     * `optimisticResponse` in the mutation().
     */
    const create = async () => {
      setFavorite({ type, esid, optimistic: true });

      await fetch(`/api/favorites`, {
        method: "POST",
        body: JSON.stringify({
          ref_type: type,
          ref_id: esid,
          ref_slug: slug,
        }),
      })
        .then(res => res.json())
        .then(() => {
          mutate();
          tagular("click", {
            actionOutcome: "SAVE",
            outboundUrl: `${process.env.NEXT_PUBLIC_DOTCOM_FRONTEND_URL}/${slug}`,
            webElement: {
              location: "SECTION",
              text: "SAVE",
              elementType: "LINK",
              ...hocTagularCtx,
            },
          });
        })
        .catch(err => {
          /**
           * Something on the network failed, roll back that optimistic favorite
           */
          setFavorite(null);
          console.error(err); // eslint-disable-line no-console
        });
    };

    /**
     * Delete a favorite
     */
    const destroy = async () => {
      const prev = favorite;
      setFavorite(null);

      await fetch(`/api/favorites/${favorite.id}`, {
        method: "DELETE",
      })
        .then(res => res.json())
        .then(() => {
          mutate(); // todo: why not updating?
          setFavorite(null); // optimistic
          tagular("click", {
            actionOutcome: "UNSAVE",
            outboundUrl: `${process.env.NEXT_PUBLIC_DOTCOM_FRONTEND_URL}/${slug}`,
            webElement: {
              location: "SECTION",
              text: "UNSAVE",
              elementType: "LINK",
              ...hocTagularCtx,
            },
          });
        })
        .catch(err => {
          /**
           * Something went wrong on the network, restore the previous favorite
           */
          setFavorite(prev);
          console.error(err); // eslint-disable-line no-console
        });
    };

    const track = () =>
      tagular("click", {
        actionOutcome: "AUTHREDIRECT",
        outboundUrl: `${process.env.NEXT_PUBLIC_DOTCOM_FRONTEND_URL}/api/auth/login`,
        webElement: {
          location: "SECTION",
          text: "SAVE",
          elementType: "LINK",
          ...hocTagularCtx,
        },
      });

    /**
     * Check if the current content item's esid is present in the user's favorites
     */
    useEffect(() => {
      const fav = user?.saves.find(f => f.esid === esid);
      if (fav) {
        setFavorite(fav);
      }
    }, [user, esid]);

    return (
      <div className={cn("relative", className)}>
        {!user && !isLoading && (
          <div className="absolute top-0 right-0 z-30 m-3 rounded-full group">
            <Login
              action="bookmark"
              className="flex items-center justify-center w-10 h-10 p-2 rounded-full text-md bg-black/50 group-hover:bg-black"
              onClick={track}
            >
              <Bookmark className="text-white" />
            </Login>

            <Tooltip>
              <h4 className="text-lg font-semibold leading-tight">
                Save your favorites!
              </h4>
              <p className="text-sm">
                <a
                  href={`${process.env.NEXT_PUBLIC_DOTCOM_FRONTEND_URL}/api/auth/login`}
                  onClick={track}
                >
                  Sign in
                </a>{" "}
                to save, get inspo &amp; more
              </p>
            </Tooltip>
          </div>
        )}

        {user && (
          <div className="absolute top-0 right-0 z-30 m-3 rounded">
            <div className="relative flex transition-all group">
              <button
                type="button"
                onClick={favorite ? destroy : create}
                className={cn(
                  "w-10 h-10 flex justify-center items-center rounded-full text-lg text-white",
                  favorite ? "bg-blue" : "bg-black/50 hover:bg-black"
                )}
              >
                {favorite ? <BookmarkFill /> : <Bookmark />}
              </button>
            </div>
          </div>
        )}

        <Component {...{ ...rest, slug }} />
      </div>
    );
  }

  WrappedComponent.propTypes = {
    esid: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["article", "poi"]).isRequired,
    slug: PropTypes.string.isRequired,
    hocTagularCtx: PropTypes.shape({
      name: PropTypes.string,
      position: PropTypes.string,
    }),
  };

  return WrappedComponent;
}
